import PropTypes from "prop-types";
import React from "react";
import { Helmet } from "react-helmet";
import { StaticQuery, graphql } from "gatsby";


const Seo = ({data, children, title, description, canonical}) => (
  <Helmet
    htmlAttributes={{
      lang: 'de'
    }}
    titleTemplate={"%s - " + data.site.siteMetadata.title}
  >
    <meta charSet="utf-8" />
    <title>{title}</title>
    <meta name="description" content={description || data.site.siteMetadata.description} />
    <link rel="canonical" href={data.site.siteMetadata.siteUrl + canonical} />
    {children}
  </Helmet>
)

Seo.propTypes = {
  canonical: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.string
}

Seo.defaultProps = {
  canonical: '',
  description: '',
  title: ''
}

export default props => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }
      }
    `}
    render={data => <Seo data={data} {...props} />}
  />
)
